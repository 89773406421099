<template>
    <div class="neuCard flex justify-around w-full mb-4">
        <div class="neuCard__order mr-8 flex items-center">
            <button @click="decreaseIndex">
              <img height="16" width="16" src="../assets/caret-up-solid.svg" alt="move-up">
            </button>
            <div class="mx-2 text-lg">{{ order+1 }}</div>
            <button @click="increaseIndex">
              <img height="16" width="16" src="../assets/caret-down-solid.svg" alt="move-down">
            </button>
        </div>
        <div class="w-3/4 overflow-ellipsis overflow-hidden">
            <div class="line-camp">{{ file.name }}</div>
            <div class="text-sm">{{ (file.size / (1024 * 1024)).toFixed(2) }} MB</div>
        </div>
        <button class="text-red-555" @click="remove()">
          <img height="16" width="16" src="../assets/trash-solid.svg" alt="delete">
        </button>
    </div>
</template>

<script>
export default {
    name: 'FileItem',
    props: {
        file: { 
            type: Object,
         },
        order: { 
            type: Number,
        }
    },
    methods: {
        remove() {
            this.$emit('remove-me', this.order)
        },
        increaseIndex() {
            this.$emit('increase-index', this.order)
        },
        decreaseIndex() {
            this.$emit('decrease-index', this.order)
        }
    }
}
</script>

<style scoped>
.line-camp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.neuCard {
  padding: 4px;
  background-color: var(--my--gray-1);
  box-shadow: var(--nue--convex-sm);
  color: var(--text--gray-5);
}

.neuCard__order {
  color: var(--my--green-1);
}

</style>