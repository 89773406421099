import jwt_decode from "jwt-decode";

// to make authenticated HTTP requests to the server api 
// using JWT authentication.
// function authHeader() {
//     // return authorization header with jwt token
//     let user = JSON.parse(localStorage.getItem('user'));

//     if (user) {
//         return { 'Authorization': `Bearer ${user.accessToken}` };
//     } else {
//         return {};
//     }
// }

function checkTokenIsValid(token) {
    try {
        let payload = jwt_decode(token)
        return new Date(payload?.exp * 1000) >  new Date()
    }
    catch {
        return false
    }   
}

export {
    // authHeader,
    checkTokenIsValid
}