<template>
  <div class="bg-gray-1 h-screen pb-20">
    <div class="container mx-auto pt-20">
      <div class="">
        <!-- 0.拖曳上傳框 -->
        <div
          v-if="uploadStatus != 'analyzing' && uploadStatus != 'completed' && uploadStatus != 'adding'"
        class="mb-4"
        >
          <div class="w-full flex flex-col items-center">
            <div
              class="dropZone"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
            >
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title">請點擊或拖曳以上傳檔案</span>
                <div class="dropZone-upload-limit-info">
                  <div>檔案限制為 PDF 格式</div>
                  <div>所有檔案加總不可超過 1000 頁</div>
                </div>
              </div>
              <input id="file" type="file" @change="onChange" />
            </div>
          </div>
        </div>

        <!-- 1.adding -->
        <div v-if="uploadStatus === 'adding'"
             class="w-4/5 mx-auto ">
          <div class="w-full rounded-full barOuter">
            <div class="rounded-full bg-gray-2 h-2" :style="{'width': uploadPercentage+'%'}"></div>
          </div>

<!--          <progress max="100" v-bind:value="uploadPercentage"></progress>-->
        </div>
        <!-- /adding -->

        <!-- 2.staged -->
        <div v-if="uploadStatus === 'staged'" class="w-4/5 mx-auto">
          <div
            v-for="(file, index) in files"
            :key="index"
            class="flex justify-center"
          >
            <FileItem
              :order="index"
              :file="file"
              @remove-me="removeFile($event, num)"
              @decrease-index="decreaseIndex($event, num)"
              @increase-index="increaseIndex($event, num)"
            />
          </div>

          <div class="flex justify-center my-12">
            <button
              @click="removeFiles"
              class="btn-cancel neuButton px-6 py-1 mr-4"
            >
              清除全部
            </button>

            <button
              @click="uploadPDF"
              class="btn-nextStep neuButton px-6 py-1"
            >
              建立書籤
            </button>
          </div>
        </div>
        <!-- /staged -->

        <!-- 3.uploading -->
        <div v-if="uploadStatus === 'uploading'" class="text-center">
          <span>檔案已經開始上傳，請不要離開畫面。提醒您：重整頁面、休眠、網路斷線會導致上傳失敗！</span>
        </div>
        <!-- /uploading -->

        <!-- 4.analyzing -->
        <div v-if="uploadStatus === 'analyzing'" class="text-center">
          <p class="mb-2">檔案已經開始解析，預計時間: {{ analyzingTime }} 分鐘</p>
          <!-- <button @click="trackTask()" class="mx-4 p-2 border border">traceTask()</button> -->
          <p>{{ taskStatus }}...</p>
        </div>
        <!-- /analyzing -->

        <!-- 5.completed -->
        <div v-if="uploadStatus === 'completed'" class="w-4/5 mx-auto">
          <div class="flex flex-col items-center justify-center mb-4">
            <img
              src="../assets/check-circle-solid.svg"
              alt=""
              class="h-20 w-20 my-4"
            />
            <span>{{ downloadMsg }}</span>
          </div>

          <div class="flex justify-center my-12">
            <button
                @click="removeFiles"
                class="btn-cancel neuButton px-6 py-1 mr-4"
            >
              清除全部
            </button>

            <button
                @click="downloadPDF"
                class="btn-nextStep neuButton px-6 py-1"
            >
              下載書籤
            </button>
          </div>

        </div>
        <!-- /completed -->
      </div>
    </div>
  </div>
</template>

<script>
import { checkTokenIsValid } from "../_helpers";
import FileItem from "./FileItem.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "index",
  components: {
    FileItem,
  },
  data() {
    return {
      files: [],
      dragging: false,
      uploadStatus: "init", // init, adding, staged, uploading, analyzing, completed
      taskId: "",
      taskStatus: "Processing", // polling: Processing, Done, Error
      polling: null,
      analyzingTime: 1,
      uploadPercentage: 0,
      downloadMsg : '合併完成，系統將於三十秒內自動下載。'
    };
  },
  computed: {
    ...mapState({
      accessToken: (state) => state.auth.user?.accessToken,
      refreshToken: (state) => state.auth.user?.refreshToken,
    }),
  },
  mounted() {
    const isRefreshTokenValid = checkTokenIsValid(this.refreshToken);
    if (!isRefreshTokenValid) {
      alert("請重新登入");
      this.logout();
      return;
    }
    this.handleRefreshToken();
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      refresh: "auth/refresh",
    }),
    handleRefreshToken() {
      const isAccessTokenValid = checkTokenIsValid(this.accessToken);
      if (!isAccessTokenValid) {
        try {
          this.refresh(this.refreshToken);
        }
        catch(e) {
          console.error(e)
        }
      }
    },
    uploadPDF() {
      this.handleRefreshToken();
      //

      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files[]", this.files[i]);
      }

      const url = `${process.env.VUE_APP_API_URL}/api/ai/uploadpdf`;
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: formData,
      };

      this.uploadStatus = "uploading";
      this.analyzingTime = this.calculateAnalyzingTime();

      return fetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            this.uploadStatus = "analyzing";
          } else {
            alert("檔案上傳失敗，請重新整理後再試一次。若多次失敗請聯繫客服。");
            console.log("uploadPDF() response", response);
          }
          return response.json();
        })
        .then((data) => {
          // console.log("uploadPDF() data.msg", data.msg);
          this.taskId = data?.task_id;
        })
        .catch((e) => {
          this.removeFiles();
          // console.log(e);
          return e;
        });
    },
    onChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.uploadStatus = "adding";
      this.createFile(files[0]);
    },
    handleCreateFileFailed() {
      if( this.files.length > 0 ) {
        this.uploadStatus = "staged";
      } else {
        this.uploadStatus = "init";
      }
    },
    createFile(file) {
      if (!file.type.match("pdf.*")) {
        alert("請上傳 PDF");
        this.dragging = false;
        this.handleCreateFileFailed();
        return;
      }
      const fileSize = (file.size)/(1024 * 1024);
      if( fileSize > 150 ) {
        alert ("單一檔案過大，上傳失敗");
        this.handleCreateFileFailed();
        return;
      }

      let allSize = 0;
      for (let i = 0; i < this.files.length; i++) {
        allSize += this.files[i].size;
      }
      allSize = (allSize / (1024 * 1024))+fileSize;
      if( allSize > 150 ) {
        alert ("合計檔案超過上限，上傳失敗");
        this.handleCreateFileFailed();
        return;
      }

      this.files.push(file);


////
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('progress', (event) =>{
        // console.log() * 100
        this.uploadPercentage = (event.loaded/event.total)*100
        if(this.uploadPercentage == 100) {
          this.uploadPercentage = 0;
          this.uploadStatus = "staged";

        }
      });
    },
    removeFiles() {
      this.uploadStatus = "init";
      this.files = [];
      this.taskId = "";
      this.taskStatus= "Processing";
      this.polling= null;
      this.analyzingTime= 1;
    },
    removeFile(num) {
      if (!this.files[num]) {
        return;
      }

      console.log(`清除 ${this.files[num].name}`);
      const newFiles = [
        ...this.files.slice(0, num),
        ...this.files.slice(num + 1),
      ];
      this.files = newFiles;

      if (this.files.length === 0) {
        this.uploadStatus = "init";
      }
    },
    increaseIndex(num) {
      if (num + 1 >= this.files.length) {
        return;
      }
      [this.files[num], this.files[num + 1]] = [
        this.files[num + 1],
        this.files[num],
      ];
      // console.log(this.files);
    },
    decreaseIndex(num) {
      if (num - 1 < 0) {
        return;
      }
      [this.files[num], this.files[num - 1]] = [
        this.files[num - 1],
        this.files[num],
      ];
      // console.log(this.files);
    },
    downloadPDF() {
      this.downloadMsg = '下載已開始，請不要離開畫面。提醒您：重整頁面、休眠、網路斷線會導致下載失敗！'
      this.handleRefreshToken();
      //
      const body = { task_id: this.taskId };
      const url = `${process.env.VUE_APP_API_URL}/api/ai/downloadpdf`;
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: JSON.stringify(body),
      };

      return fetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.arrayBuffer();
          } else {
            console.log(response);
            throw new Error("downloadPDF() response a server Error");
          }
        })
        .then((data) => {
          // set the blog type to final pdf
          const file = new Blob([data], { type: "application/pdf" });

          // process to auto download it
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = "AI書籤_" + new Date().toLocaleString() + ".pdf";
          link.click();
        })
        .catch((e) => {
          this.removeFiles();
          alert("檔案過期，下載失敗，請重新整理後再試一次。若多次失敗請聯繫客服。");
          console.log(e);
          return e;
        });
    },
    calculateAnalyzingTime() {
      let analyzingTime = 5;
      let allSize = 0;
      for (let i = 0; i < this.files.length; i++) {
        allSize += this.files[i].size;
      }
      allSize = allSize / (1024 * 1024);
      if (allSize<30) {
        return analyzingTime
      }
      analyzingTime += 5 * parseInt(allSize / 30);
      return analyzingTime;
    },
    pollTaskStatus() {
      this.polling = setInterval(() => {
        this.handleRefreshToken();
  
        const body = { task_id: this.taskId };
        // console.log(body)
        const url = `${process.env.VUE_APP_API_URL}/api/tracktask`;
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
          body: JSON.stringify(body),
        };
        console.log(body)
        return fetch(url, requestOptions)
          .then(response => response.json())
          .then((data) => {
            const dataStatus = data.status;
            console.log(dataStatus)
            switch (dataStatus) {
              case 'Processing' :
                    break;
              case 'Done' :
                this.uploadStatus = "completed";
                this.clearPollTaskStatus();
                break;
              default:
                console.log(data)
                throw new Error("pollTaskStatus() response a server Error");
            }
          })
          .catch((e) => {
            this.removeFiles();
            this.clearPollTaskStatus(); //沒有 clear ?????
            alert("檔案解析失敗，請重新整理後再試一次。若多次失敗請聯繫客服。");
            console.log(e);
            return e;
          });

      }, 30000);
    },
    clearPollTaskStatus() {
      clearInterval(this.polling);
    },
  },

  watch: {
    uploadStatus: function () {
      if (this.uploadStatus === "analyzing") {
        this.pollTaskStatus();
      }
      if (this.uploadStatus === "completed") {
        this.downloadPDF();
      }
    },
  },
};
</script>

<style scoped>
/* 上傳前 */
.dropZone {
  width: 80%;
  height: 200px;
  position: relative;
  border-radius: 8px;
  background: var(--my--gray-1);
  padding: 5px 10px;
  color: var(--text--gray-5);
  box-shadow: var(--nue--concave-soft)
}

.dropZone:hover {
  border: 2px solid var(--my--blue-1);
}

.dropZone:hover .dropZone-title {
  color: var(--my--blue-1);
}

.dropZone-info {
  color: var(--text--gray-3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.dropZone-title {
  color: var(--text--gray-5);
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.barOuter {
  box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff
}
</style>
